import React from "react";
import { GamesByCategory } from "../common/interfaces";
import GameCard from "./GameCard";
const Fade = require('react-reveal/Fade');


const GameCategoriesList = ({ gamesByCategory }: { gamesByCategory: GamesByCategory }) => {
  const categories = Object.keys(gamesByCategory)
    .filter((category) => gamesByCategory[category].length > 0)
    .sort((a: string, b: string) => ('' + a).localeCompare(b));

  return (
    <>
      {categories.map((category: string) => (
        <Fade bottom key={category}>
          <div className='games-category'>
            <label>{category}</label>
            <div className='horizontal-list'>
              {gamesByCategory[category].map(
                game => <GameCard key={game.appId} {...game} />
              )}
            </div>
          </div>
        </Fade>
      ))}
    </>
  );
};

export default GameCategoriesList;
