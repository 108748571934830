import appboxoSdk from '@appboxo/js-sdk';

export const openGame = (appId: string) => {
  appboxoSdk.send('AppBoxoWebAppOpenMiniApp', {
    app_id: appId
  });
  appboxoSdk.send('AppBoxoWebAppVibrate', {
    style: 'medium'
  });
}
