import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../assets/images/icons/arrow.svg";
const Fade = require("react-reveal/Fade");

export const ALL = "All";

type Props = {
  options: string[];
  onFilter: (option: string) => void;
}

const Filter = ({ options, onFilter }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [selected, setSelected] = useState(ALL);

  const handleFilter = (option: string) => {
    setSelected(option);
    onFilter(option);
    setIsOpen(false);
  }

  useEffect(() => {
    setFilterOptions([ALL, ...options]);
  }, [options]);

 return (
   <div className="filter">
     <div onClick={() => setIsOpen(!isOpen)}>{selected} <Arrow /></div>
     {isOpen && <Fade top distance="20px">
       <ul className={`options options__${isOpen ? 'open' : 'closed'}`}>
         {filterOptions.map((option) => (
           <li key={option} onClick={() => handleFilter(option)}>{option}</li>
         ))}
       </ul>
     </Fade>}
   </div>
 );
}

export default Filter;
