import React from "react";
import { Game } from "../common/interfaces";
import Swiper from 'react-id-swiper';
import { openGame } from '../common/utils';

const params = {
  centeredSlides: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  }
};

const CarouselItem = ({ appId, bgImg }: Game) => {
  const gameImg = require(`../assets/images/banners/${bgImg}`);

  const handleClick = () => {
    openGame(appId);
  }

  return (
    <div className='card' data-app-id={appId} onClick={handleClick}>
      <div className="top-game" style={{"backgroundImage": `url(${gameImg})`}} />
    </div>
  );
};

const TopGamesCarousel = ({ games }: { games: Game[] }) => {
  return (
    <div className="top-games-slick">
      <Swiper {...params}>
        {games.map(
          (game: Game) => <div key={game.appId}><CarouselItem {...game} /></div>
        )}
      </Swiper>
    </div>
  );
};

export default TopGamesCarousel;
