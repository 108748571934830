import { Game } from './interfaces'

export const ALL_GAMES: Game[] = [
  { 'appId': 'app35260', 'logo': 'adventure-drivers.jpg', 'category': 'Racing', 'title': 'Adventure Drivers' },
  { 'appId': 'app89675', 'logo': 'jewelish.png', 'bgImg': 'jewelish.jpg', 'category': 'Puzzle', 'title': 'Jewelish' },
  { 'appId': 'app12531', 'logo': '3d-darts.jpg', 'category': 'Sports', 'title': '3D Darts' },
  { 'appId': 'app17176', 'logo': 'SliceRushTeaser.jpg', 'category': 'Action', 'title': 'Slice Rush' },
  { 'appId': 'app23245', 'logo': 'Mahjong3d.jpg', 'bgImg': 'mahjong-3d.png', 'category': 'Board games', 'title': 'Mahjong 3D' },
  { 'appId': 'app99111', 'logo': 'pets-rush.jpg', 'category': 'Puzzle', 'title': 'Pets Rush' },
  { 'appId': 'app43798', 'logo': 'monkey-bounce.png', 'category': 'Arcade', 'title': 'Monkey Bounce' },
  { 'appId': 'app28044', 'logo': 'bubblewoods.png', 'bgImg': 'bubblewoods.jpg', 'category': 'Puzzle', 'title': 'Bubble Woods' },
  { 'appId': 'app13725', 'logo': 'jungle-roller.jpg', 'category': 'Arcade', 'title': 'Jungle Roller' },
  { 'appId': 'app71533', 'logo': 'CityDunkTeaser.jpg', 'category': 'Sports', 'title': 'City Dunk' },
  { 'appId': 'app29444', 'logo': 'onet-world.png', 'category': 'Puzzle', 'title': 'Onet World' },
  { 'appId': 'app20283', 'logo': 'SushiRollTeaser.jpg', 'category': 'Cooking', 'title': 'Sushi Roll' },
  { 'appId': 'app99942', 'logo': 'knife-rain.jpg', 'category': 'Action', 'title': 'Knife Rain' },
  { 'appId': 'app64357', 'logo': 'highway-rider-extreme.jpeg', 'category': 'Racing', 'title': 'Highway Rider Extreme' },
  { 'appId': 'app13300', 'logo': 'archery-world-tour.jpg', 'category': 'Sports', 'title': 'Archery World Tour' },
  { 'appId': 'app81934', 'logo': 'maze.jpg', 'category': 'Puzzle', 'title': 'Maze' },
  { 'appId': 'app71832', 'logo': 'rolling-panda.jpg', 'category': 'Casual', 'title': 'Rolling Panda' },
  { 'appId': 'app10512', 'logo': 'guns-and-bottles.jpg', 'category': 'Action', 'title': 'Guns and Bottles' },
  { 'appId': 'app58109', 'logo': 'piggy-night.png', 'category': 'Casual', 'title': 'Piggy Night' },
  { 'appId': 'app57839', 'logo': 'tower-mania.jpg', 'category': 'Casual', 'title': 'Tower Mania' },
  { 'appId': 'app34501', 'logo': 'knife-smash.jpg', 'category': 'Action', 'title': 'Knife smash' },
  { 'appId': 'app22629', 'logo': 'football-headz-cup-2.jpg', 'category': 'Sports', 'title': 'Football Headz Cup 2' },
  { 'appId': 'app10061', 'logo': 'TruckTrials.jpg', 'category': 'Arcade', 'title': 'Truck Trials' },
  { 'appId': 'app93483', 'logo': 'endless-truck.jpg', 'category': 'Arcade', 'title': 'Endless Truck' },
  { 'appId': 'app15036', 'logo': 'rival-rush.png', 'category': 'Racing', 'title': 'Rival Rush' },
  { 'appId': 'app70388', 'logo': 'the-little-giant.jpg', 'category': 'Arcade', 'title': 'The Little Giant' },
  { 'appId': 'app78444', 'logo': 'outcome.png', 'category': 'Arcade', 'title': 'Outcome' },
  { 'appId': 'app92218', 'logo': 'race-right.jpg', 'category': 'Racing', 'title': 'Race Right' },
  { 'appId': 'app35149', 'logo': 'gold-mine.jpg', 'category': 'Puzzle', 'title': 'Gold Mine' },
  { 'appId': 'app43081', 'logo': 'SolitaireKlondikeTeaser.jpg', 'category': 'Cards', 'title': 'Solitaire Klondike' },
  { 'appId': 'app94741', 'logo': 'candy-bubble.jpg', 'category': 'Puzzle', 'title': 'Candy Bubble' },
  { 'appId': 'app62976', 'logo': 'perfect-piano.jpg', 'category': 'Casual', 'title': 'Perfect Piano' },
  { 'appId': 'app42704', 'logo': '3d-free-kick.jpg', 'category': 'Sports', 'title': '3D Free Kick' },
  { 'appId': 'app82300', 'logo': 'table-tennis-world-tour.jpg', 'category': 'Sports', 'title': 'Table Tennis World Tour' },
  { 'appId': 'app92720', 'logo': 'domino-frenzy.png', 'category': 'Board games', 'title': 'Domino Frenzy' },
  { 'appId': 'app82727', 'logo': 'tower-crasher-3d.jpg', 'category': 'Casual', 'title': 'Tower Crash 3D' },
  { 'appId': 'app18282', 'logo': 'zoo-boom.jpg', 'category': 'Puzzle', 'title': 'Zoo boom' },
  { 'appId': 'app54123', 'logo': 'smarty-bubbles.jpg', 'category': 'Puzzle', 'title': 'Smarty Bubbles' },
  { 'appId': 'app86651', 'logo': '3d-chess.jpg', 'category': 'Board games', 'title': '3D Chess' },
  { 'appId': 'app54075', 'logo': 'find-500-differences.jpg', 'category': 'Casual', 'title': 'Find 500 differences' },
  { 'appId': 'app22190', 'logo': 'gold-miner-tom.jpg', 'category': 'Arcade', 'title': 'Gold Miner Tom' },
  { 'appId': 'app90740', 'logo': 'fruita-crash.jpg', 'category': 'Action', 'title': 'Fruita Crash' },
  { 'appId': 'app63623', 'logo': 'SudokuClassicTeaser.jpg', 'category': 'Board games', 'title': 'Sudoku Classic' },
  { 'appId': 'app96497', 'logo': 'tower-rush.jpg', 'category': 'Casual', 'title': 'Tower Rush' },
  { 'appId': 'app47603', 'logo': 'circle-rush.jpg', 'category': 'Casual', 'title': 'Circle Rush' },
  { 'appId': 'app85941', 'logo': 'PenguinSkipTeaser.jpg', 'category': 'Arcade', 'title': 'Penguin Skip' },
  { 'appId': 'app46244', 'logo': 'stick-freak.jpg', 'category': 'Action', 'title': 'Stick Freak' },
  { 'appId': 'app68536', 'logo': 'knight-tower.jpg', 'category': 'Casual', 'title': 'Knight Tower' },
  { 'appId': 'app89298', 'logo': 'SWOOOPTeaser.jpg', 'category': 'Arcade', 'title': 'SWOOP' },
  { 'appId': 'app89795', 'logo': 'birdy-rush.jpg', 'category': 'Casual', 'title': 'Birdy Rush' },
  // MarketJS games
  // { 'appId': 'app61339', 'logo': 'dodgeball.jpg', 'category': 'Action', 'title': 'Dodgeball Battle' },
  // { 'appId': 'app45002', 'logo': 'drift-boss.jpg', 'category': 'Action', 'title': 'Drift Boss' },
  // { 'appId': 'app43982', 'logo': 'fast-lane.jpg', 'category': 'Action', 'title': 'Fast Lane Racing' },
  // { 'appId': 'app68780', 'logo': 'koala-sling.jpg', 'category': 'Arcade', 'title': 'Koala Sling' },
  // { 'appId': 'app62435', 'logo': 'kawaii-jump.jpg', 'category': 'Arcade', 'title': 'Kawaii Jump' },
  // { 'appId': 'app93163', 'logo': 'bricked.jpg', 'category': 'Strategy', 'title': 'Bricked' },
  // { 'appId': 'app62873', 'logo': 'spore-hunter.jpg', 'category': 'Arcade', 'title': 'Spore Hunter' },
  // { 'appId': 'app37560', 'logo': 'ant-smash.jpg', 'category': 'Action', 'title': 'Ant Smash' },
  // { 'appId': 'app90761', 'logo': 'pop-the-virus.jpg', 'category': 'Action', 'title': 'Pop The Virus' },
  // { 'appId': 'app25138', 'logo': 'flick-basketball.jpg', 'category': 'Sports', 'title': 'Flick Basketball' },
  // { 'appId': 'app13239', 'logo': 'snowboard-hero.jpg', 'category': 'Action', 'title': 'Snowboard Hero' },
  // { 'appId': 'app42358', 'logo': 'draw-one-line.jpg', 'category': 'Strategy', 'title': 'Draw One Line' },
  // { 'appId': 'app56011', 'logo': 'sky-castle.jpg', 'category': 'Arcade', 'title': 'Sky Castle' },
  // { 'appId': 'app14239', 'logo': 'super-jump.jpg', 'category': 'Action', 'title': 'Super Jump' },
  // { 'appId': 'app26863', 'logo': 'tennis-is-war.jpg', 'category': 'Sports', 'title': 'Tennis is War' },
  // { 'appId': 'app88206', 'logo': 'jewels-crush.jpg', 'category': 'Strategy', 'title': 'Jewel Crush' },
  // { 'appId': 'app90437', 'logo': 'battleships-ready-go.jpg', 'category': 'Strategy', 'title': 'Battleships Ready Go!' },
  // { 'appId': 'app75462', 'logo': 'boat-dash.jpg', 'category': 'Action', 'title': 'Boat Dash' },
  // { 'appId': 'app55319', 'logo': 'air-hockey.jpg', 'category': 'Sports', 'title': 'Air Hockey' },
  // { 'appId': 'app64523', 'logo': 'king-of-strings.jpg', 'category': 'Music', 'title': 'King of Strings' },
  // { 'appId': 'app65527', 'logo': 'lock-master.jpg', 'category': 'Action', 'title': 'Lock Master' },
  // { 'appId': 'app80945', 'logo': 'bill-the-bowman.jpg', 'category': 'Sports', 'title': 'Bill The Bowman' },
  // { 'appId': 'app11087', 'logo': 'rise-higher.jpg', 'category': 'Strategy', 'title': 'Rise Higher' },
  // { 'appId': 'app84329', 'logo': 'puzzle-ball.jpg', 'category': 'Strategy', 'title': 'Puzzle Ball' },
  // { 'appId': 'app58382', 'logo': 'funkyplane.jpg', 'category': 'Arcade', 'title': 'Funky Plane' },
  // { 'appId': 'app55618', 'logo': 'spin-the-wheel.jpg', 'category': 'Rewards', 'title': 'Spin the Wheel' },
  // { 'appId': 'app11013', 'logo': 'flip-jump.jpg', 'category': 'Arcade', 'title': 'Flip Jump' },
  // { 'appId': 'app90769', 'logo': 'giant-humster-run.jpg', 'category': 'Action', 'title': 'Giant Hamster Run' },
  // { 'appId': 'app79763', 'logo': 'monster-truck-driving.jpg', 'category': 'Action', 'title': 'Monster Truck Racing' },
  // { 'appId': 'app88813', 'logo': 'monkey-bubble-shooter.png', 'category': 'Action', 'title': 'Monkey Bubble Shooter' },
]
