import React from "react";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { ReactComponent as CloseIcon } from "../assets/images/icons/close.svg";

export const ALL = "All";

type Props = {
  value: string;
  onChange: (searchString: string) => void;
}

const Search = ({ value, onChange }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }

  const handleClear = () => {
    onChange('');
  }

  return (
    <div className="search">
      <SearchIcon />
      <input value={value} onChange={handleChange} placeholder='Search...' />
      <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClear} />
    </div>
  );
}

export default Search;
