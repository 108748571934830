import React, { useEffect, useState } from "react";
import TopGamesCarousel from "./TopGamesCarousel";
import { Game, GamesByCategory } from "../common/interfaces";
import GameCategoriesList from "./GameCategoriesList";
import { ALL_GAMES } from "../common/games";
import Filter, { ALL } from "./Filter";
import Search from "./Search";


const gamesByCategory: GamesByCategory = {};
ALL_GAMES.forEach((game: Game) => {
  gamesByCategory[game.category] = gamesByCategory[game.category]
    ? [...gamesByCategory[game.category], game]
    : [game];
});

const TOP_GAMES: Game[] = [ALL_GAMES[1], ALL_GAMES[4], ALL_GAMES[7]];

const App = () => {
  const [filteredGamesByCategory, setFilteredGamesByCategory] = useState<GamesByCategory>({});
  const [filterBy, setFilterBy] = useState<string>(ALL);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    let filteredResult: GamesByCategory = {};
    filterBy === ALL
      ? Object.assign(filteredResult, gamesByCategory)
      : Object.assign(filteredResult, { [filterBy]: gamesByCategory[filterBy] });

    Object.keys(filteredResult).forEach((category: string) => {
      filteredResult[category] = filteredResult[category].filter(
        (game) => game.title.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    });

    setFilteredGamesByCategory(filteredResult);
  }, [filterBy, searchString]);

  return (
    <div className="App">
      <div className="games">
        <header className="games__header">
          <Filter options={Object.keys(gamesByCategory)} onFilter={setFilterBy} />
          <img src={require("../assets/images/banners/gameboxo.svg")} alt="Gameboxo" />
          <br />
        </header>
        <hr />
        <Search value={searchString} onChange={setSearchString} />
        {filterBy === ALL && searchString === "" && (
          <TopGamesCarousel games={TOP_GAMES} />
        )}
        <GameCategoriesList gamesByCategory={filteredGamesByCategory} />
      </div>
    </div>
  );
}

export default App;
