import React from 'react';
import { Game } from "../common/interfaces";
import { openGame } from '../common/utils';

const GameCard = ({ appId, logo, title }: Game) => {
  const gameImg = require(`../assets/images/logos/${logo}`);

  const handleClick = () => {
    openGame(appId);
  }

  return (
    <div className="card" data-app-id={appId} onClick={handleClick}>
      <div className="card__img" style={{"backgroundImage": `url(${gameImg})`}} />
      <label className="card__title">{title}</label>
    </div>
  )
};

export default GameCard;
